import { Link, useSearchParams } from '@remix-run/react';
import { useEffect, useState } from 'react';

import { contactSupportSchema } from '~/data/contact-support/.schema/contactSupportSchema';
import { useUser } from '~/data/user/hooks/useUser';

import { useHasIframeParam } from '~/helpers/hooks/useHasIframeParam';

import { Form } from '~/components/.shared/form/Form';

export const CONTACT_SUPPORT_FORM_ID = 'contact-support-form';

export function ContactSupportForm() {
  const isIframe = useHasIframeParam();
  const [currentUrl, setCurrentUrl] = useState('');
  const user = useUser();
  const [searchParams] = useSearchParams();

  const fieldIsReadOnly = Boolean(user);

  const nameFieldDefaultValue = user ? `${user.data.attributes.firstName} ${user.data.attributes.lastName}` : '';
  const emailFieldDefaultValue = user ? user.data.attributes.email : '';

  const emailFieldClassName = `${user ? 'bg-neutral-95' : ''}`;
  const nameFieldClassName = `${user ? 'bg-neutral-95' : ''}`;

  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <>
      {!isIframe && (
        <>
          <h1 className="mb-6 text-3xl">Contact support</h1>
          <p className="mb-4">
            Have a question or need help with something? Our support team are here to help. Have an idea for a feature?{' '}
            <Link to={'/share-feedback'}>Suggest a feature</Link>
          </p>
        </>
      )}
      <Form
        id={CONTACT_SUPPORT_FORM_ID}
        schema={contactSupportSchema}
        method="post"
        action={`/contact-support${isIframe ? '?iframe=true' : ''}`}
        className="w-full"
      >
        <div className="2xs:flex gap-4">
          <Form.InputField
            type="text"
            name="name"
            label="Name"
            className={nameFieldClassName}
            wrapperClassName="2xs:w-1/2"
            defaultValue={nameFieldDefaultValue}
            readOnly={fieldIsReadOnly}
          />
          <Form.InputField
            type="email"
            name="email"
            label="Email"
            className={emailFieldClassName}
            wrapperClassName="2xs:w-1/2"
            defaultValue={emailFieldDefaultValue}
            readOnly={fieldIsReadOnly}
          />
        </div>
        <Form.TextareaField
          name="message"
          label="Message"
          errorLocation={isIframe ? 'top' : 'bottom'}
          validateEvents={['onChange']}
        />
        <input type="hidden" name="subject" value="Content Workflow Support" />
        <input type="hidden" name="currentUrl" value={isIframe ? `${searchParams.get('origin')}` : currentUrl} />
        {!isIframe && <Form.SubmitButton className="w-full flex justify-center">Submit</Form.SubmitButton>}
      </Form>
    </>
  );
}
